<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { identityServerUsers } from "@/services/api/iam.api";

const emits = defineEmits(["clear", "on-user-selected"]);

const props = defineProps({
  maxHeight: {
    Type: Number,
    default: undefined,
  },
});

const identityServerSelectedUser = ref(undefined);
const identityServerIsLoading = ref(false);
const searchIdentityServer = ref(undefined); // gaan we watchen
const identityServerItems = ref([]); // items voor identity autocomplete

const menuProps = computed(() => {
  if (props.maxHeight) {
    return { maxHeight: props.maxHeight };
  } else {
    return undefined;
  }
});

watch(
  () => searchIdentityServer.value,
  () => {
    console.log("identityServerSelection, watch searchIdentityServer ", searchIdentityServer.value);
    fillItems(searchIdentityServer.value);
  }
);

watch(
  () => identityServerSelectedUser.value,
  () => {
    console.log("identityServerSelection, watch identityServerSelectedUser", identityServerSelectedUser.value);
    emits("on-user-selected", identityServerSelectedUser.value);
  }
);

async function fillItems(searchText) {
  if (searchText) {
    identityServerIsLoading.value = true;
    const response = await identityServerUsers(searchText, 9);
    console.log("identityServerSelection, watch searchIdentityServer response ", response);
    const items = response.data.map((el) => ({ ...el, text: `${el.userName}${el.isAssigned ? " (is al toegewezen)" : ""}`, disabled: el.isAssigned }));
    //         console.log("UserDetails, watch searchIdentityServer items ", items);
    identityServerItems.value = items;
    identityServerIsLoading.value = false;
  }
}

function onClear() {
  emits("clear");
}
</script>

<template>
  <v-autocomplete
    v-model="identityServerSelectedUser"
    ref="selector"
    :items="identityServerItems"
    :search-input.sync="searchIdentityServer"
    label="IdentityServer gebruikers"
    hide-details="auto"
    hide-no-data
    :loading="identityServerIsLoading"
    item-text="text"
    item-value="id"
    placeholder="Zoek gebruiker in INNAX IdentityServer..."
    persistent-placeholder
    return-object
    outlined
    prepend-inner-icon="mdi-cloud"
    clearable
    loader-height="4"
    :menu-props="menuProps"
    @click.clear="onClear"
  />
</template>
