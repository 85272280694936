<script lang="ts" setup>
import { computed } from "vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { mdiTrashCanOutline } from "@mdi/js";

const emits = defineEmits(["remove-user-login"]);

const { formatDateUtcDT } = useDateTimeFormatter();

const props = defineProps({
  addLink: undefined,
  gridData: undefined,
  readOnly: { Type: Boolean, default: () => true },
});

const _headers = [
  { text: "Gebruikersnaam", value: "userName" },
  { text: "Volledige naam", value: "fullName" },
  { text: "E-mail", value: "email" },
  { text: "Laatste inlog", value: "lastLogin" },
  { text: "Actief", value: "isActive", align: "center" },
  { text: "", value: "delete-link", sortable: false, width: 20, showOnAllowDelete: true },
];

const headers = computed(() => {
  if (!props.readOnly) {
    return _headers.filter((x) => !x.showOnAllowDelete || x.showOnAllowDelete == true);
  } else {
    return _headers.filter((x) => !x.showOnAllowDelete);
  }
});

function onRemoveLink(id) {
  emits("remove-user-login", id);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-col cols="auto"> </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right">
        <v-btn v-if="addLink" dark class="primary mt-4" :to="addLink">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-right">
        <slot name="add-relation"></slot>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" hide-default-footer :items="gridData">
      <template v-slot:[`item.lastLogin`]="{ item }">
        {{ formatDateUtcDT(item.lastLogin) }}
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        {{ item.isActive === true ? "Ja" : "Nee" }}
      </template>
      <template v-slot:[`item.delete-link`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="onRemoveLink(item.id)" icon small plain v-on="on">
              <v-icon small>{{ mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Verwijder koppeling</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
