<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DateInput from "@/components/general/DateInput.vue";
import { getUser, addUser, patchUser, severityCode } from "@/services/api/iam.api";
import { getUserLogins, upsertUserLogins } from "@/services/api/iam.api";
import { identityServerUserAdd, identityServerCheckUserName } from "@/services/api/iam.api";
import UserLoginsGrid from "@/components/iam/UserLoginsGrid.vue";
import IdentityServerSelection from "@/components/iam/IdentityServerSelection.vue";
import router from "@/router/index";

const emits = defineEmits(["update-user"]);

const props = defineProps({
  userId: {
    type: String,
    default: undefined,
  },
  addModus: {
    type: Boolean,
    default: false,
  },
});

const editMode = ref(false);
const selectedData = ref({
  id: undefined,
  valFrom: undefined,
  valTill: undefined,
  modBy: undefined,
  name: undefined,
  firstname: undefined,
  middlename: undefined,
  lastname: undefined,
  email: undefined,
  phoneNumber: undefined,
});
const logins = ref([]);

const selectedIdentityUser = ref(undefined);
const addUserLoginDisabled = ref(true);
const doSaveToIdentityServer = ref(false);
const disableSaveIdentityServer = ref(false);
const identityServerUserName = ref("");
const identityServerUserNameInvalid = ref(false);
const isSaving = ref(false);

const readOnly = computed(() => !props.addModus && !editMode.value);
const disableSave = computed(() => (!props.addModus && !selectedData.value.id)  || (doSaveToIdentityServer.value && identityServerUserNameInvalid.value) || isSaving.value);

watch(
  () => props.userId,
  () => {
    console.log("UserDetails, watch userId ", props.userId, props.addModus);
    if (!props.addModus) {
      fetchData();
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => logins.value,
  () => {
    checkAdd();
    if (logins.value.length > 0) {
      disableSaveIdentityServer.value = true;
      doSaveToIdentityServer.value = false;
      identityServerUserName.value = undefined;
    } else {
      disableSaveIdentityServer.value = false;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => identityServerUserName.value,
  () => {
    console.log("UserDetails, watch identityServerUserName ", identityServerUserName.value);
    if (identityServerUserName.value) {
      checkUserName(identityServerUserName.value);
    }
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("UserDetails, fetchData ", props.userId);
  if (props.userId && props.userId !== "") {
    const response = await getUser(props.userId);
    console.log("UserDetails, fetchData response ", response.data);

    if (response && !response.error) {
      selectedData.value = response.data;
      const responseLogins = await getUserLogins(props.userId);
      console.log("UserOverview, responseLogins ", responseLogins);
      if (response && !response.error) {
        logins.value = responseLogins.data;
      } else {
        proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
      }
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
  }
}

async function checkUserName(name) {
  let result = false;
  if (name.length > 0) {
    const response = await identityServerCheckUserName(name);
    if (response && !response.error) {
      result = response.data;
    }
  }
  identityServerUserNameInvalid.value = result;
}

function onUserSelectedAdd(user) {
  console.log("UserDetails, onUserSelectedAdd", user);
  logins.value = [];
  if (user != null) {
    selectedData.value.id = undefined;
    selectedData.value.firstname = user.firstname;
    selectedData.value.middlename = user.middlename;
    selectedData.value.lastname = user.lastname;
    selectedData.value.name = !user.fullName ? user.userName : user.fullName;
    selectedData.value.email = user.email;
    selectedData.value.phoneNumber = user.phoneNumber;

    let loginUser = {
      userId: undefined,
      userName: user.userName,
      fullName: user.fullName,
      isActive: true,
      provider: "INNAX",
      reference: user.id,
    };

    logins.value.push(loginUser);
  }
  console.log("UserDetails, updateSelection ", selectedData.value, logins.value);
}

function onUserSelectedEdit(user) {
  console.log("UserDetails, onUserSelected", user);
  if (user != null) {
    selectedIdentityUser.value = {
      userId: undefined,
      userName: user.userName,
      fullName: user.fullName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      provider: "INNAX",
      reference: user.id,
    };
  } else {
    selectedIdentityUser.value = undefined;
  }
  checkAdd();
}

function checkAdd() {
  let result = false;
  console.log("UserDetails, checkAdd", selectedIdentityUser.value);
  if (selectedIdentityUser.value != undefined) {
    const index = logins.value.findIndex((x) => x.reference === selectedIdentityUser.value.reference);
    console.log("UserDetails, checkAdd", index);
    result = index != -1;
  } else {
    result = true;
  }
  console.log("UserDetails, checkAdd", result);
  addUserLoginDisabled.value = result;
}

function onAddUserLogin() {
  console.log("UserDetails, onAddUserLogin", selectedIdentityUser.value);
  logins.value.push(selectedIdentityUser.value);
  checkAdd();
}

function onClear() {
  logins.value = [];
}

function onRemoveUserLogin(userLoginId) {
  const index = logins.value.findIndex((x) => x.id === userLoginId);
  if (index >= 0) {
    logins.value.splice(index, 1);
  }
  checkAdd();
}

function checkDateValid() {
  return { on: ["input"] };
}

async function onEdit() {
  editMode.value = true;
}

async function onCancel() {
  logins.value = [];
  if (props.addModus) {
    router.push({ name: "IAM-UsersOverview" });
  } else {
    editMode.value = false;
    doSaveToIdentityServer.value = false;
    identityServerUserName.value = undefined;
    fetchData();
  }
}

const obs = ref(null);

async function onSave() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let response = undefined;
    if (props.addModus) {
      response = await addUser(selectedData.value);
      console.log("UserDetails, onSave add ", response);
    } else {
      response = await patchUser(selectedData.value.id, selectedData.value);
      console.log("UserDetails, onSave patch ", response);
    }
    let savedLogin = true;
    if (response.severity <= severityCode.warning) {
      const userId = props.addModus ? response.data : selectedData.value.id;
      if (doSaveToIdentityServer.value && identityServerUserName.value.length > 0) {
        const identityServerUserId = await addIdentityServerUser(identityServerUserName.value, selectedData.value);
        console.log("UserDetails, onSave addIdentityServerUser ", identityServerUserId);
        if (identityServerUserId) {
          let loginUser = {
            userId: userId,
            userName: identityServerUserName.value,
            fullName: selectedData.value.name,
            isActive: true,
            provider: "INNAX",
            reference: identityServerUserId,
          };

          logins.value.push(loginUser);
        }
        console.log("UserDetails, onSave logins ", logins.value);
      }
      savedLogin = await saveIdentityServerUser(userId);
    }
    //als saveIdentityServerUser fout geeft geen fout of waarschuwing geven voor hoofdobject
    if (savedLogin) {
      switch (response.severity) {
        // case severityCode.hint:
        // case severityCode.warning:
        //   $toaster.warning(response.message);
        //   break;
        case severityCode.error:
          proxy.$toaster.error("Bewaren van gebruiker is mislukt! " + response.error);
          break;
      }
      if (response.severity <= severityCode.warning) {
        if (props.addModus) {
          router.push({ name: "IAM-UsersOverview" });
        } else {
          editMode.value = false;
          emits("update-user");
        }
      }
    }
  }
  doSaveToIdentityServer.value = false;
  identityServerUserName.value = undefined;
  isSaving.value = false;
}

async function saveIdentityServerUser(userId) {
  const response = await upsertUserLogins(userId, logins.value);
  let savedLogin = true;

  switch (response.severity) {
    case severityCode.error:
      savedLogin = false;
      proxy.$toaster.error("Bewaren van gebruiker login is mislukt! " + response.error);
      break;
  }
  return savedLogin;
}

async function addIdentityServerUser(userName, user) {
  let userId = undefined;
  const response = await identityServerUserAdd(userName, user);
  console.log("UserDetails, addIdentityServerUser response ", response);
  if (response.severity <= severityCode.warning) {
    userId = response.data;
  }
  switch (response.severity) {
    case severityCode.hint:
    case severityCode.warning:
      proxy.$toaster.warning(response.message);
      break;
    case severityCode.error:
      proxy.$toaster.error("Bewaren van IdentityServer gebruiker is mislukt! " + response.error);
      break;
  }
  return userId;
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <ValidationObserver ref="obs">
      <v-form>
        <v-container fluid pa-0>
          <v-row v-show="addModus">
            <v-col cols="6">
              <identity-server-selection v-show="addModus" @on-user-selected="onUserSelectedAdd" @clear="onClear"></identity-server-selection>
            </v-col>
          </v-row>
          <v-row v-show="addModus">
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- :class="{noBorders:true}" -->
              <ValidationProvider name="Volledige naam" rules="required" v-slot="{ errors }">
                <v-text-field
                  hide-details="auto"
                  label="Naam"
                  placeholder="volledige naam"
                  persistent-placeholder
                  :outlined="!readOnly"
                  :class="{ noBorders: readOnly }"
                  v-model="selectedData.name"
                  :error-messages="errors"
                  :readonly="readOnly"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                hide-details="auto"
                label="Voornaam"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.firstname"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                hide-details="auto"
                label="Tussenvoegsel"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.middlename"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Achternaam"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.lastname"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Referentie"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.reference"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Omschrijving"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.description"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ValidationProvider name="Email" rules="email|required" v-slot="{ errors }">
                <v-text-field
                  hide-details="auto"
                  label="Email"
                  :outlined="!readOnly"
                  :class="{ noBorders: readOnly }"
                  persistent-placeholder
                  v-model="selectedData.email"
                  :readonly="readOnly"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Telefoonnummer"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.phoneNumber"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Afdeling"
                placeholder="bedrijf- en/of afdelingsnaam"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.department"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                label="Functie"
                :outlined="!readOnly"
                :class="{ noBorders: readOnly }"
                persistent-placeholder
                v-model="selectedData.function"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                <date-input v-model="selectedData.validFrom" label="Geldig vanaf" persistent-placeholder :errors="errors" :readonly="readOnly" outlined></date-input>
              </ValidationProvider>
            </v-col>
            <v-col>
              <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.validFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                <date-input
                  v-model="selectedData.validTill"
                  label="Geldig tot"
                  :outlined="!readOnly"
                  :class="{ noBorders: readOnly }"
                  persistent-placeholder
                  :errors="errors"
                  :readonly="readOnly"
                ></date-input>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-container>
        <v-layout class="mt-5">
          <v-container class="boxed" fluid>
            <header>Gekoppelde authenticatie</header>
            <v-row v-show="editMode">
              <v-col>
                <identity-server-selection :max-height="140" @on-user-selected="onUserSelectedEdit"></identity-server-selection>
              </v-col>
              <v-col>
                <v-btn dark class="primary mt-4" @click="onAddUserLogin" :disabled="addUserLoginDisabled">
                  <v-icon dark center>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <user-logins-grid :grid-data="logins" :read-only="!editMode" @remove-user-login="onRemoveUserLogin"></user-logins-grid>
          </v-container>
        </v-layout>
        <v-container v-if="!readOnly" fluid>
          <v-row>
            <v-col cols="auto">
              <v-switch v-model="doSaveToIdentityServer" :disabled="disableSaveIdentityServer" label="De gebruiker toevoegen aan INNAX IdentityServer" :readonly="readOnly" hide-details></v-switch>
            </v-col>
            <v-col cols="4">
              <ValidationProvider name="Gebruikersnaam" v-slot="{ errors }">
                <v-text-field
                  hide-details="auto"
                  label="Gebruikersnaam"
                  :outlined="!readOnly"
                  :class="{ noBorders: readOnly }"
                  persistent-placeholder
                  v-model="identityServerUserName"
                  :readonly="readOnly"
                  :errors="errors"
                  :error-messages="errors"
                  :disabled="disableSaveIdentityServer || !doSaveToIdentityServer"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col v-if="identityServerUserNameInvalid" cols="auto">
              <v-alert outlined type="error"> Gebruikersnaam bestaat al </v-alert>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid mt-5>
          <v-row>
            <v-col cols="auto" class="form-group" v-if="readOnly">
              <v-btn class="primary" v-on:click="onEdit()" :disabled="disableSave">
                <v-icon dark left>mdi-pencil</v-icon>
                Wijzigen
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-else>
              <v-btn class="primary" v-on:click="onSave()" :disabled="disableSave">
                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                Opslaan
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-show="!readOnly">
              <v-btn v-on:click="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
